import { request } from "../../api/API"

export const getDistributionCount = () => {
    return request.get('/custom_panel/getDashboardCount/');
}

export const getTransactionSummary = () => {
    return request.get('/custom_panel/balanceSummary/');
}

export const getCustomerActivity = () => {
    return request.get('/custom_panel/customerActivity/');
}

export const getBanner = () => {
    return request.get('/custom_panel/getUploadedBannerImage/');
}

export const getCard = () => {
    return request.get('/custom_panel/getCardImage/');
}