import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Sidebar from "../pages/Sidebar/Sidebar";
import AddDistributer from "../pages/AddDistributer/AddDistributer";
import DistributersList from "../pages/DistributersList/DistributersList";
import RetailersList from "../pages/RetailersList/RetailersList";
import AddRetailer from "../pages/AddRetailer/AddRetailer";
import Profile from "../pages/Profile/Profile";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import DistributerView from "../pages/DistributerView/DistributerView";
import TransferPoint from "../pages/TransferPoint/TransferPoint";
import FinanceLocker from "../pages/FinanceLocker/FinanceLocker";
import MyTransaction from "../pages/MyTransaction/MyTransaction";
import UserList from "../pages/UserList/UserList";
import SendNotification from "../pages/SendNotification/SendNotification";
import UploadBanner from "../pages/UploadBanner/UploadBanner";
import RetailerView from "../pages/RetailersList/RetailerView";
import UserView from "../pages/UserList/UserView";
// import TransactionView from "../pages/MyTransaction/TransactionView";
import TransactionView from "../pages/TransactionView/TransactionView";



const AuthenticationRoutes = () => {
    // const location = useLocation();


    // console.log("Current route:", location?.pathname);

    return (
        <>
            <Routes>
               
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/addDistributer" element={<AddDistributer />} />
                <Route path="/distributersList" element={<DistributersList />} />
                <Route path="/retailersList" element={<RetailersList />} />
                <Route path="/addRetailer" element={<AddRetailer />} />
                <Route path="/myTransaction" element={<MyTransaction />} />
                <Route path="/transactionView/:id" element={<TransactionView />} />
                <Route path="/userList" element={<UserList />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/sendNotification" element={<SendNotification />} />
                <Route path="/uploadBanner" element={<UploadBanner />} />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/DistributerView/:id/:isEdit" element={<DistributerView />} />
                <Route path="/retailerView/:id/:isEdit" element={<RetailerView />} />
                <Route path="/userView/:id" element={<UserView />} />
                <Route path="/transferPoint" element={<TransferPoint />} />
                <Route path="/transferPoint/:id" element={<TransferPoint />} />
                <Route path="/financeLocker" element={<FinanceLocker />} />
                <Route path="/financeLocker/:id" element={<FinanceLocker />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
        </>
    )
}

export default AuthenticationRoutes;