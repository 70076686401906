import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import './TransferPoint.css';
import { getTransactionSummary, getShowUserRole, getTransferName, addTransfer, getDistributerView } from "./service";

const TransferPoint = () => {

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    useEffect(() => {
        fetchTransactionSummary();
        fetchUserRole();
    }, []);

    const params = useParams();
    console.log(params, "chsih")

    const [transactionSummary, setTransactionSummary] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [userNames, setUserNames] = useState([]);
    const [transferPoint, setTransferPoint] = useState('');
    const [description, setDescription] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedDistributor, setSelectedDistributor] = useState(null);
    const [distributerData, setDistributerData] = useState();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    console.log("transactionSummary12", transactionSummary);
    console.log("userRole", userRole);
    console.log("selectedRole", selectedRole);

    const handleChangeTransferPoint = (e) => {
        setTransferPoint(e.target.value);
        console.log("transferPoint", transferPoint)
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    //Get transfer to name
    const handleChangeUserRole = async (event) => {
        const role_name = event.target.value;
        setSelectedRole(role_name);
        const data = {
            user_role: role_name,
        };
        console.log("data123", data);
        try {
            const response = await getTransferName(data);
            if (response.status === 200 || response.status === 201) {
                console.log("userResponse", response);
                // const names = response.data.payload.map(user => user.name);
                const users = response.data.payload.map(user => ({ name: user.name, userId: role_name === "Superadmin" ? user.id : user.user }));
                setUserNames(users);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    // Add transfer
    const handleTransfer = async () => {
        if (!transferPoint) {
            errorNotify("Transfer point is required");
            return;
        }

        if (!selectedRole) {
            errorNotify("User role is required");
            return;
        }
        if (!distributerData && !selectedUserId) {
            errorNotify("Transfer to is required");
            return;
        }
        if (!description) {
            errorNotify("Description is required");
            return;
        }

      if(transferPoint>transactionSummary.balance_points)  
      {
        errorNotify("Insufficient Wallet Balance");
        return;
      }



        const data = {
            transfer_points: transferPoint,
            user_role: selectedRole,
            transfer_to: distributerData ? distributerData.user : selectedUserId,
            description: description
        }
        console.log("transferData", data);
        try {
            const response = await addTransfer(data);
            if (response.status === 200 || response.status === 201) {
                console.log('transferResponse', response);
                if (response.data.success) {
                    notify(response.data.message || "Transaction successful!");
                    setTransferPoint("");
                    setSelectedRole("");
                    setSelectedUserId("");
                    setDescription("");
                    selectedDistributor(null);
                    setUserNames([]);
                } else {
                    toast.error(response.data.message || "Transaction failed");
                }
            }
        } catch (error) {
            console.error("Error sending form data:", error)
            errorNotify(error.response?.data?.transfer_points[0]);
        }
    }

    //Get wallet balance
    const fetchTransactionSummary = async () => {
        try {
            const response = await getTransactionSummary()
            if (response.status === 200 || response.status === 201) {
                setTransactionSummary(response.data.payload)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Get user role
    const fetchUserRole = async () => {
        try {
            const response = await getShowUserRole()
            if (response.status === 200 || response.status === 201) {
                setUserRole(response.data.payload)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [params.id]);



    useEffect(() => {
        if (distributerData) {
            setSelectedRole(distributerData.user_role);
            console.log("distributerData.user", distributerData);

            setSelectedDistributor(distributerData);

        }


    }, [distributerData]);

    const fetchData = async () => {
        try {
            const response = await getDistributerView(params.id)
            if (response.status === 200 || response.status === 201) {
                setDistributerData(response.data);
                console.log("transferData", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Transfer points</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className={`pb-4 pb-md-4 ${sidebarOpen ? 'sidebar-open-distributer' : 'sidebar-close-distributer'}`}>
                {/* ========== Grid 1 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <div className="transfer-point-input-wrap">
                            <label className="transfer-point-input-label">Wallet balance</label>
                            <TextField
                                id="filled-basic"
                                // label="Full Name"
                                variant="filled"
                                className='transfer-input'
                                value={transactionSummary.balance_points}
                            />
                        </div>
                        <div className="transfer-point-input-wrap pt-3">
                            <label className="transfer-point-input-label">Enter Transfer Point</label>
                            <TextField
                                id="filled-basic"
                                label="Transfer point"
                                variant="filled"
                                className='transfer-input'
                                value={transferPoint}
                                onChange={handleChangeTransferPoint}
                            />
                        </div>
                        <div className="transfer-input-wrap pt-3">
                            <label className="transfer-point-label">Select Role</label>
                            <FormControl variant="filled" className="select-role-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedRole}
                                    onChange={handleChangeUserRole}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {userRole.map((role) => (
                                        <MenuItem key={role.id} value={role.role_name}>
                                            {role.role_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="transfer-input-wrap pt-3">

                            {selectedDistributor ? (
                                <>
                                    <TextField
                                        id="filled-basic"
                                        label="Name"
                                        variant="filled"
                                        className='transfer-input'
                                        value={selectedDistributor.name}
                                    />
                                </>
                            ) : (
                                <div>
                                    <label className="transfer-point-label">Name</label>
                                    <FormControl variant="filled" className="select-role-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={selectedUserId}
                                            onChange={(event) => setSelectedUserId(event.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {userNames.map((user, index) => (
                                                <MenuItem key={index} value={user.userId}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}

                        </div>
                        <div className="transfer-input-wrap pt-3">
                            <label className="transfer-point-label">Description</label>
                            <TextField
                                label=""
                                multiline
                                rows={4}
                                variant="filled"
                                fullWidth
                                value={description}
                                onChange={handleChangeDescription}
                            />
                        </div>
                        <div className="transfer-point-btn-wrap">
                            <button className="cancel-btn">
                                Cancel
                            </button>
                            <button className="transfer-point-btn" onClick={handleTransfer}>
                                Transfer
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default TransferPoint;