import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userDetail: "",
    keepMeSignIn: false,
    isDistributorWelcomImageShown:false
}

const userLoginSlice = createSlice({
    name: "userLogin",
    initialState,
    reducers: {
        setUserLogin(state, { payload }) {
            return { ...state, ...payload };
        },
    

    },
});

export const {
    setUserLogin,
} = userLoginSlice.actions;

export default userLoginSlice.reducer;


