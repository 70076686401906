import { request } from "../../api/API"
import { multipartRequest } from "../../api/API"

export const getFinance = (data) => {
    return multipartRequest.post('/custom_panel/getCustomerDetails/', data);
}

export const getUserData = (id) => {
    return request.get(`/customer/customerList/${id}`);
}

export const lockDevice = (id, data) => {
    return multipartRequest.put(`/customer/lockDevice/${id}/`, data);
}

export const unInstallPolicy = (data) => {
    return multipartRequest.post(`/customer/uninstallPolicy/`, data);
}

export const getSearchIMEI = (data) => {
    return request.get(`/custom_panel/getSearchIMEI/?search=${data}`);
}