import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { sendNotification } from "./service";
import './SendNotification.css';

const SendNotification = () => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleSubmit = async () => {
        if (!title) {
            errorNotify("Title is required");
            return;
        }

        if (!description) {
            errorNotify("Description is required");
            return;
        }
        const data = {
            title: title,
            description: description,
        };
        try {
            const response = await sendNotification(data);
            if (response.status === 200 || response.status === 201) {
                console.log('response', response);
                notify(response?.data?.message);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.error);
        }
    };

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-4 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Send Notification</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className={`pt-2 pb-5 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                {/* ========== Grid 1 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <div className="send-notification-input-wrap">
                            <label className="send-notification-label">Title</label>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                className='send-notification-input'
                                value={title}
                                onChange={handleChangeTitle}
                            />
                        </div>
                        <div className="send-notification-input-wrap pt-3">
                            <label className="send-notification-label">Description</label>
                            <TextField
                                id="filled-basic"
                                label="Description"
                                multiline
                                rows={4}
                                variant="filled"
                                className='send-notification-input'
                                value={description}
                                onChange={handleChangeDescription}
                            />
                        </div>
                        <button className="send-notification-btn" onClick={handleSubmit}>
                            Send
                        </button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default SendNotification;