import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import './DistributerView.css';
import { getDistributerView, updateDistributer } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const DistributerView = () => {

    const [distributerData, setDistributerData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [editUserData, setEditUserData] = useState({
        name: "",
        company_name: "",
        mobile_number: "",
        email: "",
        address: "",
        country: "",
        state: "",
        city: ""
    })

    const params = useParams();
    console.log(params, "id")

    useEffect(() => {
        fetchData();
    }, [params.id]);

    useEffect(() => {
        if (params.isEdit === 'true') {
            setIsEdit(true);
        }
    }, [params.isEdit]);


    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    console.log("distributerData", distributerData)
    const fetchData = async () => {
        try {
            const response = await getDistributerView(params.id)
            if (response.status === 200 || response.status === 201) {
                setDistributerData(response.data);
                setEditUserData(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleUpdate = async () => {
        const updatedData = {
            id: params.id,
            full_name: editUserData.name,
            company_name: editUserData.company_name,
            mobile_number: editUserData.mobile_number,
            country: editUserData.country,
            state: editUserData.state,
            city: editUserData.city,
            address: editUserData.address,
            email: editUserData.email
        };
        console.log("updatedData", updatedData)

        try {
            const response = await updateDistributer(params.id, updatedData);
            if (response.status === 200 || response.status === 201) {
                console.log("Update successful:", response.data);
                fetchData();
                notify(response.data.message);

            } else {
                console.error("Error updating data:", response.statusText);
            }
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const handleOnChange = (e) => {
        setEditUserData({ ...editUserData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-4 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Distributor View</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-2 pb-4">
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className="user-view-box">
                            <div>
                                <h6>Customer  Details</h6>
                            </div>
                            <div className="user-view-box1">
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Name :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {isEdit ? (
                                            <>
                                                <input
                                                    // variant="filled"
                                                    name="name"
                                                    value={editUserData.name}
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />
                                            </>
                                        ) : (
                                            <span className="detail-value">{distributerData.name ? distributerData.name : '-'}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Company name :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <>
                                                    <input
                                                        value={editUserData.company_name}
                                                        name="company_name"
                                                        onChange={(e) => handleOnChange(e)}
                                                        className='detail-input'
                                                    />
                                                </>
                                            ) : (
                                                <span className="detail-value">{distributerData.company_name ? distributerData.company_name : '-'}</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Mobile :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <input
                                                    value={editUserData.mobile_number}
                                                    name="mobile_number"
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />

                                            ) : (
                                                <span className="detail-value">{distributerData.mobile_number ? distributerData.mobile_number : '-'}</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Email id :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ?
                                                (
                                                    <input
                                                        value={editUserData.email}
                                                        name="email"
                                                        onChange={(e) => handleOnChange(e)}
                                                        className='detail-input'
                                                    />

                                                ) : (
                                                    <span className="detail-value">{distributerData.email ? distributerData.email : '-'}</span>

                                                )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">parent name :</span>
                                    </div>
                                    <div>
                                        <span className="detail-value">{distributerData.created_by ? distributerData.created_by : '-'}</span>
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Owner Name :</span>
                                    </div>
                                    <div>
                                        <span className="detail-value">{distributerData.name ? distributerData.name : '-'}</span>
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">country :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <input
                                                    value={editUserData.country}
                                                    name="country"
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />
                                            ) : (
                                                <span className="detail-value">{distributerData.country ? distributerData.country : '-'}</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">State :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <input
                                                    value={editUserData.state}
                                                    name="state"
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />
                                            ) : (
                                                <span className="detail-value">{distributerData.state ? distributerData.state : '-'}</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">City :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <input
                                                    value={editUserData.city}
                                                    name="city"
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />
                                            ) : (
                                                <span className="detail-value">{distributerData.city ? distributerData.city : '-'}</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="user-detail-wrap">
                                    <div className="user-detail">
                                        <span className="detail-label">Address :</span>
                                    </div>
                                    <div className="user-detail-input-wrap">
                                        {
                                            isEdit ? (
                                                <input
                                                    value={editUserData.address}
                                                    name="address"
                                                    onChange={(e) => handleOnChange(e)}
                                                    className='detail-input'
                                                />
                                            ) : (
                                                <span className="detail-value">{distributerData && distributerData.address ? distributerData.address : '-'}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className="disatributer-update-button-wrap">
                {isEdit ? (
                    <>
                        <button onClick={handleUpdate} className='disatributer-update-button mb-4'>
                            Update
                        </button>
                    </>
                ) : ("")}
            </div>

        </>
    );
}

export default DistributerView;
