import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { LuArrowLeft } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { images } from "../../config";
import './ResetPassword.css';

const ResetPassword = () => {

    return (
        <>
            <Container maxWidth="xxl" sx={{ padding: { lg: 0, xs: 0 } }} >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <div
                            className='sign-in-text-wrap'
                        >
                            <div className='sign-in-text'>
                                <div>
                                    <img src={images.iSecureLogo} alt="image" className='i-secure-logo' />
                                </div>
                                <div className='sign-in-text'>
                                    <h2>Check Your Email</h2>
                                    <h6>We sent a password reset link to<br></br>
                                        <span>Example123@gmail.com</span> </h6>
                                </div>
                                <div className='reset-password-btn'>
                                    <NavLink to="/setNewPassword">
                                        <button>Reset Password</button>
                                    </NavLink>
                                </div>

                                <div className='resend-text'>
                                    {/* <NavLink to=""> */}
                                        <div>
                                            Don't receive the email?
                                            <a>
                                                Click to resend
                                            </a>
                                        </div>
                                    {/* </NavLink> */}
                                    <div>
                                        <NavLink to="/login">
                                            <span>
                                                <LuArrowLeft className='left-arrow' />  Back to login
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                        <img
                            src={images.loginImg}
                            alt="image"
                            className='main-img'
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ResetPassword;