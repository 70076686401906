import React, { useState, useEffect } from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
} from '@mui/material';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { getTransaction, getTransactionExcelExcel } from "./service";
import { CiEdit } from "react-icons/ci";
import TextField from '@mui/material/TextField';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import dayjs from 'dayjs';
import './MyTransaction.css';


const columns = [
    { id: 'sr', label: 'Sr' },
    { id: 'transaction_no', label: 'Transaction Number' },
    { id: 'transaction_from', label: 'Transaction From' },
    { id: 'transaction_to', label: 'Transaction To' },
    { id: 'transaction_date', label: 'Transaction Date' },
    { id: 'points', label: 'Point' },
    { id: 'transaction_type', label: 'Type' },
    { id: 'balance_type', label: 'Balance Type' },
    { id: 'edit', lable: 'Edit' }
];

const MyTransaction = () => {

    const [transaction, setTransaction] = useState({ name: "" });
    const [selectedFormDate, setSelectedFormDate] = useState();
    const [selectedToDate, setSelectedToDate] = useState();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sr');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(0);
    const [transactionData, setTransactionData] = useState([]);
    const [sortedDataList, setSortedDataList] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const navigate = useNavigate();

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleSort = (property, order) => {
        setOrder(order);
        setOrderBy(property);
    };

    const sortData = () => {
        setSortedDataList([...transactionData].sort((a, b) => {
            let valueA = a[orderBy];
            let valueB = b[orderBy];
            // Handle undefined values
            // Handle undefined or null values
            if (valueA === undefined || valueA === null) valueA = '';
            if (valueB === undefined || valueB === null) valueB = '';


            // Check if both values are numeric
            if (!isNaN(valueA) && !isNaN(valueB)) {
                valueA = parseFloat(valueA);
                valueB = parseFloat(valueB);
            } else {
                // Ensure strings are compared case-insensitively
                valueA = valueA.toString().toLowerCase();
                valueB = valueB.toString().toLowerCase();
            }

            if (order === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        }));
    };

    useEffect(() => {
        sortData();
    }, [order, orderBy, transactionData]);

    useEffect(() => {
        handleSubmit();
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeTransaction = (e) => {
        setTransaction({
            ...transaction,
            [e.target.name]: e.target.value,
        });
    }

    const handleFormdateChange = (newDate) => {
        setSelectedFormDate(newDate);
        const formDate = dayjs(newDate).format('YYYY-MM-DD');
        console.log("Formatted Date:", formDate);
    };

    const handleTodateChange = (newDate) => {
        setSelectedToDate(newDate);
        const toDate = dayjs(newDate).format('YYYY-MM-DD');
        console.log("FormattedTo Date:", toDate);
    };

    const handleRefresh = () => {
        setTransaction({ ...transaction, name: '' });
        setSelectedFormDate(null);
        setSelectedToDate(null);
        setRefresh(refresh === 0 ? 1 : 0);
    };

    useEffect(() => {
        handleSubmit();
    }, [refresh]);

    const handleExportExcel = async () => {
        const data = {
            transaction_to__username: transaction,
        }
        console.log("transactionExcel", data);
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const toDate = selectedToDate ? dayjs(selectedToDate).format('YYYY-MM-DD') : "";
        try {
            const response = await getTransactionExcelExcel(transaction, fromDate, toDate);
            console.log("response", response);
        }
        catch (error) {
            console.error("Error sending form data:", error);
        }
    }

    const handleSubmit = async () => {
        const data = {
            transaction_to__username: transaction,
        }

        if (selectedFormDate > selectedToDate) {
            errorNotify("Please select valid date range");
            return;
        }

        console.log("retailer", data);
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const toDate = selectedToDate ? dayjs(selectedToDate).format('YYYY-MM-DD') : "";
        try {
            const pageSize = rowsPerPage || 10;
            const response = await getTransaction(data, transaction, fromDate, toDate, page, pageSize);
            if (response.status === 200 || response.status === 201) {
                const results = response.data.results;
                console.log("transaction123444", response.data);
                setSelectedFormDate(null);
                setSelectedToDate(null);
                if (results.length === 0) {
                    errorNotify("Transaction history not found");
                    setCount(0);
                    setTransactionData([]);
                } else {
                    console.log("count", response.data.count)
                    setTransactionData(results);
                    setCount(response.data.count);
                    console.log("reaponseretailer", results)
                    // notify(response.data.message);
                }
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    const handleClickUserView = (rowId) => {;
        navigate(`/transactionView/${rowId}`);
        console.log("navigate");
        console.log(rowId,"rooowwId");
    }

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4">
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">My Transaction</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-4">
                <div className="search-data-bg">
                    <div className="transaction-list-form">
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="transaction-input-wrap">
                                    <div>
                                        <label className="transaction-label">Search Transaction to</label>
                                    </div>
                                    <div className="input-wrap">
                                        <input name="name" value={transaction.name} onChange={handleChangeTransaction} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="transaction-date-label">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoItem label="From Date">
                                        <DesktopDatePicker
                                            value={selectedFormDate}
                                            onChange={handleFormdateChange}
                                            className="custom-input"
                                            renderInput={(params) => <TextField {...params} />}
                                            maxDate={dayjs()}
                                        />
                                    </DemoItem>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="transaction-date-label">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoItem label="To Date">
                                        <DesktopDatePicker
                                            value={selectedToDate}
                                            onChange={handleTodateChange}
                                            className="custom-input"
                                            renderInput={(params) => <TextField {...params} />}
                                            maxDate={dayjs()}
                                        />
                                    </DemoItem>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <div className="distributer-btns-wrap">
                            <div>
                                <MdRefresh className="refresh-icon" onClick={handleRefresh} />
                            </div>
                            <div>
                                <button className="cancel-btn" onClick={handleExportExcel}>Export</button>
                            </div>
                            <div>
                                <button className="submit-btn" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container maxWidth="xxl" className="pt-4 pb-5">
                <TableContainer component={Paper}>
                    <Table className="transaction-list-table-wrap">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <>
                                        <TableCell key={column.id} className="transaction-list-table-header-bg"
                                            sx={{
                                                border: 'none',
                                                '&:last-child': { border: 0 }
                                            }}
                                        >
                                            <div className="header-text-wrapper">
                                                <div className="header-text">
                                                    {column.label}
                                                </div>

                                                <div>
                                                    <div>
                                                        <IoIosArrowUp
                                                            onClick={() => handleSort(column.id, 'desc')} className="up-icon"
                                                        >
                                                        </IoIosArrowUp>
                                                    </div>

                                                    <div>
                                                        <IoIosArrowDown
                                                            onClick={() => handleSort(column.id, 'asc')} className="down-icon"
                                                        >
                                                        </IoIosArrowDown>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="transaction-list-table">
                            {sortedDataList.map((row, index) => (
                                <TableRow key={row.id || index}>
                                    <TableCell sx={{ padding: 0 }}>{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell sx={{ padding: 0 }}>{row.transaction_no}</TableCell>
                                    <TableCell>{row.transaction_from}</TableCell>
                                    <TableCell>{row.transaction_to}</TableCell>
                                    <TableCell> {row.transaction_date ? dayjs(row.transaction_date).format('YYYY-MM-DD') : '-'}</TableCell>
                                    <TableCell>{row.points}</TableCell>
                                    <TableCell>
                                        <button className="transaction-type">
                                            {row.transaction_type}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        {row.balance_type ? row.balance_type : '-'}
                                    </TableCell>
                                    <TableCell>
                                        <CiEdit onClick={()=>handleClickUserView(row.id)} className="edit-icon"/> 
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

            </Container>
        </>
    )
}

export default MyTransaction;

