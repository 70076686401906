
export const images = {
    loginImg: require("../assets/images/sign-in-img.png"),
    // loginImg: require("../assets/images/Rectangle 16182.png"),
    // loginImg: require("../assets/images/Rectangle 16114.png"),
    iSecureLogo: require("../assets/images/i-secure-logo.png"),
    androidLogo: require("../assets/images/android-logo.png"),
    navbarLogo: require("../assets/images/navbar-logo.png"),
    adminImg: require("../assets/images/profile-icon.png"),
    navbarLogo1: require("../assets/images/i-secure-img.png"),
    dashboardIcon: require("../assets/images/dashboard-icon.png"),
    distributerIcon: require("../assets/images/distributer-icon.png"),
    retailerIcon: require("../assets/images/retailer-icon.png"),
    transactionIcon: require("../assets/images/transaction-icon.png"),
    userListIcon: require("../assets/images/user-list-icon.png"),
    financeLocakerIcon: require("../assets/images/locker-icon.png"),
    iSecureicon: require("../assets/images/i-secure-icon.png"),
    changepasswordicon: require("../assets/images/lock-icon.png"),
    advertisingIcon: require("../assets/images/advertising-icon.png"),
    showAdvertisingIcon: require("../assets/images/advertising1-icon.png"),
    logoutIcon: require("../assets/images/log-out-icon.png"),
    keyImage: require("../assets/images/key-img.png"),
    scanner: require("../assets/images/scanner-img.png"),
    debitIcon: require("../assets/images/debit-point.png"),
    creditIcon: require("../assets/images/credit-point.png"),
    balanceIcon: require("../assets/images/balance-point.png"),
    inActiveIcon: require("../assets/images/inactive-icon.png"),
    installIcon: require("../assets/images/install-icon.png"),
    deActiveIcon: require("../assets/images/deactive-icon.png"),
    unInstallIcon: require("../assets/images/uninstall-icon.png"),
    dashboardImg: require("../assets/images/dashboard-img.png"),
    distributionIcon: require("../assets/images/distribution-img.png"),
    customerIcon: require("../assets/images/customers-img.png"),
    retailerIcon1: require("../assets/images/retailer-img.png"),
    phoneImage: require("../assets/images/phone-img.png"),
    albumImage:require("../assets/images/album-image.png"),
    userImage:require("../assets/images/user.png")
}
