import React, { useEffect, useState } from "react";
import AuthenticationRoutes from "./AuthenticationRoutes";
import UnAuthenticationRoutes from "./UnAuthenticationRoutes";
import Sidebar from "../pages/Sidebar/Sidebar";
// import { BrowserRouter as Router,Route, Routes,Navigate  } from "react-router-dom";
import store from "../redux/store/store";
import { useSelector } from 'react-redux';
import {
    useLocation,
} from "react-router-dom";

const App = () => {

    const [userDetails, setUserDetails] = useState(null);
    const { userDetail } = useSelector((state) => state.userLogin);
    const location = useLocation();


    console.log("Current route:1", location.pathname);

    useEffect(() => {
        // Get token from storage
        const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');

        if (token) {
            // Here you should fetch user details from your API
            // For now, simulate setting user details from Redux
            setUserDetails(userDetail);

            // Alternatively, if you have an action to fetch user details, you can dispatch it
            // dispatch(fetchUserDetails(token)); // Example action
        }
    }, [userDetail, location.pathname]);

    // if (userDetail && userDetails === null) {
    //     return <div>LOADING...........................</div>
    // }
    return (
        <>
            {/* <Router> */}
            {/* {user?.userDetail?.token ? (<><Router><Sidebar><AuthenticationRoutes /></Sidebar></Router></>) : <UnAuthenticationRoutes />} */}
            {console.log("userDetail", userDetails && userDetails.email && userDetails.token)}
            {userDetails && userDetails.email && userDetails.token ? (<><Sidebar><AuthenticationRoutes /></Sidebar></>) : <UnAuthenticationRoutes />}
            {/* </Router> */}

        </>
    );
}

export default App;
