import { combineReducers } from "redux";
import userLoginSlice from "../../pages/Login/LoginSlice/LoginSlice"

 
const mainReducer = combineReducers({
    userLogin: userLoginSlice,   
});

const rootReducer = (state, action) => {
    if (action.type === "CLEAR_STORE") {
        // Reset each slice state to its initial state
        state = undefined;
    }
    return mainReducer(state, action);
};

export default rootReducer;
