import { request } from "../../api/API"
import { multipartRequest } from "../../api/API"

export const getDistributerView = (id) => {
    return request.get(`/custom_panel/getDistributorList/${id}`);
}

export const updateDistributer = (id, data) => {
    return multipartRequest.patch(`/custom_panel/updateDistributorDetails/${id}/`, data);
}


