import { request } from "../../api/API"
import { multipartRequest, downloadFile } from "../../api/API"

export const getTransaction = (data, userName, fromDate, toDate, page, pageSize) => {
    return request.get(`/custom_panel/transactionHistory/?search=${userName.name}&transaction_from_date=${fromDate}&transaction_to_date=${toDate}&page=${page + 1}&page_size=${pageSize}`, data);
}

export const getTransactionExcelExcel = (transaction, fromDate, toDate,) => {
    return downloadFile(`/custom_panel/getTransactionListExcel/?search=${transaction.name}&from_date=${fromDate}&to_date=${toDate}`, "transactions_data.xlsx",);
}

// Transaction view 
export const getTransactionView = (id) => {
    return request.get(`/custom_panel/transactionHistory/${id}`);
}

export const updateTransferPoint = (id, data) => {
    return multipartRequest.put(`/custom_panel/updateTransferPoints/${id}/`, data);
}

