import React, { useState } from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { NavLink } from "react-router-dom";
import { images } from "../../config";
import './SetNewPassword.css';
import { styled } from '@mui/material/styles';


const CustomFilledInput = styled(FilledInput)({
    borderRadius: '8px',
    '&:before': {
        borderBottom: 'none',
    },
    '&:after': {
        borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
    },
    root: {
        '& .MuiFilledInput-root': {
            borderBottom: 'none',
        },
    },
});


const SetNewPassword = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Container maxWidth="xxl" sx={{ padding: { lg: 0, xs: 0 } }} >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <div
                            className='sign-in-text-wrap'
                        >
                            <div className='sign-in-text'>
                                <div>
                                    <img src={images.iSecureLogo} alt="image" className='i-secure-logo' />
                                </div>
                                <div className='sign-in-text mb-5'>
                                    <h2>Set a new password</h2>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div>
                                            <TextField
                                                id="filled-basic"
                                                label="Enter Your Username"
                                                variant="filled"
                                                className='user-iput'
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className='password-input1'>
                                            <FormControl variant="filled" className='password-input'>
                                                <InputLabel htmlFor="filled-adornment-password" className='password-label'>Enter Your Password</InputLabel>
                                                <CustomFilledInput
                                                    id="filled-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <span>Must be at least 8 character </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='sign-in-btn'>
                                    <NavLink to="/confirmPassword">
                                        <button>Confirm Password</button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                        <img
                            src={images.loginImg}
                            alt="image"
                            className='main-img'
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default SetNewPassword;