import React from 'react';
import { Routes, Route,Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import SetNewPassword from '../pages/SetNewPassword/SetNewPassword';
import ConfirmPassword from '../pages/ConfirmPassword/ConfirmPassword';

const UnAuthenticationRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgotpassword" element={<ForgotPassword />} />
    <Route path="/resetPassword" element={<ResetPassword />} />
    <Route path="/setNewPassword" element={<SetNewPassword />} />
    <Route path="/confirmPassword" element={<ConfirmPassword />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export default UnAuthenticationRoutes;

