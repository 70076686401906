import { request } from "../../api/API"
import { multipartRequest } from "../../api/API"

export const uploadBanner = (data) => {
    return multipartRequest.post('/custom_panel/uploadBanner/', data);
}

export const uploadCardData = (data) => {
    return multipartRequest.post('/custom_panel/uploadCard/', data);
}
