import { request } from "../../api/API"
import { multipartRequest, downloadFile } from "../../api/API"

// export const getDistributer = (data, userName) => {
//     return request.get(`/custom_panel/getDistributorList/?search=${userName.name}`, data);
// }

export const getDistributer = (data, userName, selectedCountry, selectedState, selectedCity, status, page, pageSize) => {
    return request.get(`/custom_panel/getDistributorList/?search=${userName.name}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&status=${status}&page=${page + 1}&page_size=${pageSize}`, data);
}

export const getCountry = () => {
    return request.get('/custom_panel/getCountry/');
}

export const getState = (data) => {
    return request.post('/custom_panel/getState/', data);
}

export const getCity = (data) => {
    return request.post('/custom_panel/getCity/', data);
}

export const getDistributerExcel = (userName, selectedCountry, selectedState, selectedCity, status) => {
    return downloadFile(`/custom_panel/distributorExcel/?search=${userName.name}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&status=${status}`, "distributor_data.xlsx",);
}

export const distributerStatus = (data) => {
    return request.post('/custom_panel/updateDistributorStatus/', data);
}