// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Sidebar from "./pages/Sidebar/Sidebar";
// import Routers from "./Router/Routes";

// function App() {
//   const { userDetail } = useSelector((state) => state.userLogin);

//   return (
//     <Router>
//       <div className="App">
//         {userDetail.email && userDetail.token ? (
//           <Sidebar>
//             <Routes>
//               <Route path="/*" element={<Routers />} />
//             </Routes>
//           </Sidebar>
//         ) : (
//           <Routes>
//             <Route path="/*" element={<Routers />} />
//           </Routes>
//         )}
//       </div>
//     </Router>
//   );
// }

// export default App;


import React from "react";
import Routes from "./Router/Routes";
import { Router } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (

    <div className="App">
      <BrowserRouter>
        {/* <Router> */}
          <Routes />
        {/* </Router> */}
        </BrowserRouter >
    </div>
    

  );
}

export default App;



// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// // import AuthenticationRoutes from './Router/AuthenticationRoutes';
// // import UnAuthenticationRoutes from './Router/UnAuthenticationRoutes';
// import { useSelector } from 'react-redux';

// function App() {
//   const { userDetail } = useSelector((state) => state.userLogin);

//   return (
//     <Router>
//       <Routes>
//         {userDetail && userDetail.email && userDetail.token ? (
//           <Route path="/*" element={<AuthenticationRoutes />} />
//         ) : (
//           <Route path="/*" element={<UnAuthenticationRoutes />} />
//         )}
//       </Routes>
//     </Router>
//   );
// }

// export default App;

