import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { updateProfile } from "./service";
import {useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { setUserLogin } from "../Login/LoginSlice/LoginSlice";
import './Profile.css';

const Profile = () => {
    const userDetail = useSelector((state) => state.userLogin.userDetail);
    const [editUserData, setEditUserData] = useState({
        name: "",
        email: "",
        mobile_number: "",
    })
    const [mobileNumberError, setMobileNumberError] = useState('');
    const dispatch = useDispatch()

    console.log("userDetail1234", userDetail)

    //
    useEffect(() => {
        console.log("USER DETAILS;- ,",userDetail)
        if (userDetail) {
            setEditUserData({
                name: userDetail.name || "",
                email: userDetail.email || "",
                mobile_number: userDetail.mobile_number ? userDetail.mobile_number : "",
            });
        }
    }, [userDetail]);


    const handleOnChange = (e) => {
        setEditUserData({ ...editUserData, [e.target.name]: e.target.value })
    }

    const handleChangeMobile = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setMobileNumberError(false);
            setEditUserData({ ...editUserData, mobile_number: '' });
            return;
        }

        const isNumeric = /^[0-9]*$/.test(value.replace(/\+91/, ''));

        if (!isNumeric) {
            setMobileNumberError(true);
        } else if (value.startsWith("+91")) {
            setMobileNumberError(value.length === 13 ? false : true);
        } else {
            setMobileNumberError(value.length === 10 ? false : true);
        }
        setEditUserData({ ...editUserData, mobile_number: value });
    };

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleUpdate = async () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(editUserData.email)) {
            errorNotify("Please enter a valid email address");
            return;
        }

        if (mobileNumberError || editUserData.mobile_number.trim() === '') {
            errorNotify("Please enter a valid mobile number");
            return;
        }

        const updatedData = {
            id: userDetail.id,
            name: editUserData.name,
            email: editUserData.email,
            mobile_number: editUserData.mobile_number,
        };
        console.log("updatedData", updatedData)

        try {
            const response = await updateProfile(userDetail.id, updatedData);
            if (response.status === 200 || response.status === 201) {
                console.log("Update profile", response.data.message);
                notify(response.data.message);
                // setEditUserData({
                //     name: "",
                //     email: "",
                //     mobile_number: "",
                // });
                // setEditUserData(updatedData);

                 // Create the updated userDetail object
                 const updatedUserDetail = {
                    ...userDetail, // Spread existing userDetail to preserve other data
                    ...editUserData, // Overwrite with the updated fields from editUserData
                  };
  
                // Dispatch the action to update the state
                dispatch(setUserLogin({ userDetail: updatedUserDetail }));
            } else {
                console.error("Error updating data", response.statusText);
            }
        } catch (error) {
            console.error("Error updating data", error);
            errorNotify(error.response.data.message);
        }
    };


    // const handleUpdate = async () => {
    //     const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    //     if (!emailPattern.test(editUserData.email)) {
    //         errorNotify("Please enter a valid email address");
    //         return;
    //     }
    
    //     if (mobileNumberError || editUserData.mobile_number.trim() === '') {
    //         errorNotify("Please enter a valid mobile number");
    //         return;
    //     }
    
    //     const updatedData = {
    //         id: userDetail.id,
    //         name: editUserData.name,
    //         email: editUserData.email,
    //         mobile_number: editUserData.mobile_number,
    //     };
    
    //     try {
    //         const response = await updateProfile(userDetail.id, updatedData);
    //         if (response.status === 200 || response.status === 201) {
    //             notify(response.data.message);
    
    //             // Fetch the updated user data
    //             // const updatedUserDetail = await fetchUserProfile(userDetail.id);
    //             setEditUserData({
    //                 name: updatedUserDetail.name,
    //                 email: updatedUserDetail.email,
    //                 mobile_number: `+91${updatedUserDetail.mobile_number}`,
    //             });
    
    //             // Optionally update the Redux store
    //             // dispatch({ type: 'SET_USER_DETAIL', payload: updatedUserDetail });
    //         } else {
    //             console.error("Error updating data", response.statusText);
    //         }
    //     } catch (error) {
    //         console.error("Error updating data", error);
    //         errorNotify(error.response.data.message);
    //     }
    // };
    

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4 pb-4">
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Profile</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-2 pb-5">
                {/* ========== Grid 1 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <div className="profile-input-wrap">
                            <label className="profile-label">Full Name</label>
                            <TextField
                                id="filled-basic"
                                label="Full Name"
                                variant="filled"
                                className='profile-input'
                                name="name"
                                value={editUserData.name}
                                // onChange={(e) => handleOnChange(e)}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="profile-input-wrap pt-3">
                            <label className="profile-label">Email Id</label>
                            <TextField
                                id="filled-basic"
                                label="Email ID"
                                variant="filled"
                                className='profile-input'
                                name="email"
                                value={editUserData.email}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                        <div className="profile-input-wrap pt-3">
                            <label className="profile-label">Mobile Number</label>
                            <TextField
                                id="filled-basic"
                                // label="Mobile Number"
                                variant="filled"
                                className='profile-input'
                                name="mobile_number"
                                value={editUserData.mobile_number}
                                onChange={(e) => handleChangeMobile(e)}
                                error={mobileNumberError}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className="input-adornment-custom-profile">
                                            +91
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <h3 className={mobileNumberError ? "invalid-message" : ""}>
                            {mobileNumberError ? "Invalid Mobile Number" : ''}
                        </h3>
                        <button className="profile-update-btn mt-4" onClick={handleUpdate}>
                            Update
                        </button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Profile;