
import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import './UploadBanner.css';
import { uploadBanner, uploadCardData } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadBanner = () => {
    const [bannerImage, setBannerImage] = useState(null);
    const [cardImage, setCardImage] = useState(null);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [cardPreview, setCardPreview] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // const handleBannerChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const imageUrl = URL.createObjectURL(file);
    //         setBannerImage(imageUrl);
    //     }
    // };


    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleBannerChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBannerImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBannerPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleCardChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCardImage(file);
            setCardImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setCardPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleUploadBanner = async () => {
        const formData = new FormData();
        formData.append('image', bannerImage);
        console.log("bannerImage", bannerImage);
        try {
            const response = await uploadBanner(formData);
            if (response.status === 200 || response.status === 201) {
                console.log("upload banner", response);
                notify(response?.data?.message);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    const handleUploadCard = async () => {
        const formData = new FormData();
        formData.append('card_image', cardImage);
        console.log("cardImage123", cardImage);
        try {
            const response = await uploadCardData(formData);
            console.log("response", response)
            if (response.status === 200 || response.status === 201) {
                console.log("upload card", response);
                notify(response?.data?.message);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <h2 className="header-text">Upload Banner and card</h2>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pb-5">
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <div className={`upload-section ${sidebarOpen ? 'upload-section-open' : 'upload-section-close'}`}>
                            <h3>Upload Banner</h3>
                            <div className="upload-box">
                                <label htmlFor="banner-upload" className="banner-upload">
                                    <div className="upload-placeholder">
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            sx={{
                                                backgroundColor: '#878787',
                                                color: '#ffffff',
                                                borderRadius: '8px',
                                                fontSize: '16px',
                                                textTransform: 'capitalize',
                                                padding: '12px 25px 12px 25px',
                                                letterSpacing: '1.5px',
                                                fontFamily: 'Satoshi-Regular',
                                                marginTop: '20px',
                                                '&:hover': {
                                                    backgroundColor: '#878787',
                                                },
                                            }}
                                        >
                                            Choose File
                                            <VisuallyHiddenInput
                                                type="file"
                                                id="banner-upload"
                                                onChange={handleBannerChange}
                                            />
                                        </Button>
                                        {bannerImage && (
                                            <div className="selected-file-box">
                                                <p className="selected-file-text">Selected file: {bannerImage.name}</p>
                                                {bannerPreview && (
                                                    <img
                                                        src={bannerPreview}
                                                        alt="Selected Banner"
                                                        className="selected-image"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <p className="file-size-info">(image size not more than 400 KB)</p>
                            <button className="banner-submit-btn" onClick={handleUploadBanner}>
                                Submit
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={`upload-section ${sidebarOpen ? 'upload-section-open' : 'upload-section-close'}`}>
                            <h3>Upload Card</h3>
                            <div className="upload-box">
                                <label htmlFor="banner-upload" className="banner-upload">
                                    <div className="upload-placeholder">
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            sx={{
                                                backgroundColor: '#878787',
                                                color: '#ffffff',
                                                borderRadius: '8px',
                                                fontSize: '16px',
                                                textTransform: 'capitalize',
                                                padding: '12px 25px 12px 25px',
                                                letterSpacing: '1.5px',
                                                fontFamily: 'Satoshi-Regular',
                                                marginTop: '20px',
                                                '&:hover': {
                                                    backgroundColor: '#878787',
                                                },
                                            }}
                                        >
                                            Choose File
                                            <VisuallyHiddenInput
                                                type="file"
                                                id="banner-upload"
                                                onChange={handleCardChange}
                                            />
                                        </Button>
                                        {cardImage && (
                                            <div className="selected-file-box">
                                                <p className="selected-file-text">Selected file: {cardImage.name}</p>
                                                {cardPreview && (
                                                    <img
                                                        src={cardPreview}
                                                        alt="Selected Card Image"
                                                        className="selected-image"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <p className="file-size-info">(image size not more than 400 KB)</p>
                            <button className="banner-submit-btn" onClick={handleUploadCard}>
                                Submit
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default UploadBanner;
