import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MdOutlineEmail } from "react-icons/md";
import { LuArrowLeft } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { images } from "../../config";
import './ForgotPassword.css';

const ForgotPassword = () => {

    return (
        <>
            <Container maxWidth="xxl" sx={{ padding: { lg: 0, xs: 0 } }} >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <div
                            className='sign-in-text-wrap'
                        >
                            <div className='sign-in-text'>
                                <div>
                                    <img src={images.iSecureLogo} alt="image" className='i-secure-logo' />
                                </div>
                                <div className='sign-in-text'>
                                    <h2>Forgot Password</h2>
                                    <h6>No Worries,we’ll send you <br></br> reset instructions. </h6>
                                </div>
                                <div class="email-input-wrap">
                                    <div class="email-container">
                                        <input type="text" placeholder="Enter Your Email" />
                                        <MdOutlineEmail className="email-icon" />
                                    </div>

                                </div>
                                <div className='reset-password-btn'>
                                <NavLink to="/resetPassword">
                                        <button>Reset Password</button>
                                        </NavLink>
                                </div>
                                <NavLink to="/login">
                                    <div className='back-to-login-text d-flex align-items-center justify-content-center'>
                                        <div>
                                            <LuArrowLeft className='left-arrow'/>
                                        </div>
                                        <div>
                                            <span>
                                                Back to login
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                        <img
                            src={images.loginImg}
                            alt="image"
                            className='main-img'
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ForgotPassword;