import React, { useState, useEffect } from "react"
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getParentRole, getParentUser, getCountry, getState, getCity, addRetailer } from "./service"
import './AddRetailer.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const CustomFilledInput = styled(FilledInput)({
    borderRadius: '8px',
    '&:before': {
        borderBottom: 'none',
    },
    '&:after': {
        borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
    },
    root: {
        '& .MuiFilledInput-root': {
            borderBottom: 'none',
        },
    },
});


const AddRetailer = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [masterDistributor, setMasterDistributor] = useState('');
    const [superDistributor, setSuperDistributor] = useState('');
    const [distributor, setDistributor] = useState('');
    const [parentRole, setParentRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [parentUserName, setParentUserName] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState();
    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [city, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [distributerName, setDistributerName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [gstError, setGstError] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('')

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeCity = (event) => {
        const selectedCityValue = event.target.value;
        setSelectedCity(selectedCityValue);
    }

    const handleChangeDistributerName = (event) => {
        setDistributerName(event.target.value);
    }

    const handleChangeEmail = (e) => {
        const email = e.target.value;
        setEmailAddress(email);
        if (email === '') {
            setEmailError('');
        } else {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (emailPattern.test(email)) {
                setEmailError('');
            } else {
                setEmailError('Invalid email address');
            }
        }
    };

    // const handleChangeMobile = (e) => {
    //     setMobileNumber(e.target.value);
    // }

    const handleChangeMobile = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setMobileNumberError(false);
            setMobileNumber('');
            return;
        }

        const isNumeric = /^[0-9]*$/.test(value.replace(/\+91/, ''));

        if (!isNumeric) {
            setMobileNumberError(true);
        } else if (value.startsWith("+91")) {
            setMobileNumberError(value.length === 13 ? false : true);
        } else {
            setMobileNumberError(value.length === 10 ? false : true);
        }
        setMobileNumber(value);
    };

    const handleChangeCompanyName = (e) => {
        setCompanyName(e.target.value);
    }

    const handleChaneGstNumber = (e) => {
        // setGstNumber(e.target.value);
        const value = e.target.value.toUpperCase();
        setGstNumber(value);

        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
        // ^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$

        // 06BZAHM6385P6Z2

        // 22ABCDE1234F1Z5
        if (!gstRegex.test(value)) {
            setGstError('Invalid GST Number');
        } else {
            setGstError('');
        }
    }

    const handleChangeUserName = (e) => {
        setUserName(e.target.value);
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        if (confirmPassword) {
            setError(value !== confirmPassword ? 'Password does not match' : '');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
        if (value) {
            setError(value !== password ? 'Password does not match' : '');
        } else {
            setError('');
        }
    };

    const handleChangeMasterDistributer = (event) => {
        setMasterDistributor(event.target.value);
    }

    const handleChangeSuperDistributer = (event) => {
        setSuperDistributor(event.target.value);
    }

    const handleDistributer = (event) => {
        setDistributor(event.target.value);
    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value);
    }

    const { userDetail } = useSelector((state) => state.userLogin);
    console.log("retailer123", userDetail);

    const handleAddRetailer = async () => {
        if (
            // !selectedUserId || 
            // !selectedRole || 
            (userDetail.user_role !== 'Distributor' && (!selectedUserId || !selectedRole)) ||
            !distributerName ||
            !userName ||
            !companyName ||
            !mobileNumber ||
            !emailAddress ||
            !selectedCountry ||
            !selectedState ||
            !selectedCity ||
            !password ||
            !confirmPassword ||
            !address) {
            errorNotify('All fields are required');
            return;
        }
        const data = {
            // parent_id: selectedUserId,
            // parent_role: selectedRole,
            parent_id: userDetail.user_role === 'Distributor' ? userDetail.id : selectedUserId,
            parent_role: userDetail.user_role === 'Distributor' ? 'Distributor' : selectedRole,
            full_name: distributerName,
            username: userName,
            company_name: companyName,
            mobile_number: mobileNumber,
            email: emailAddress,
            gst_number: gstNumber,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            password: password,
            confirm_password: confirmPassword,
            address: address
        };
        console.log("data-addDistributer123", data);
        try {
            const response = await addRetailer(data);
            if (response.status === 200 || response.status === 201) {
                console.log("data-addDistributer", response);
                notify(response.data.message);
                // setDistributerName("");
                // setEmailAddress("");
                // setSelectedRole("");
                // setMobileNumber("");
                // setCompanyName("");
                // setGstNumber("");
                // setUserName("");
                // setPassword("");
                // setConfirmPassword("");
                // setParentUserName("");
                // setCountry("");
                // setState("");
                // setCity("");
                // setAddress("");
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    useEffect(() => {
        fetchParentRole();
        fetchCountry();
    }, []);

    //Get Country list
    const fetchCountry = async () => {
        try {
            const response = await getCountry()
            if (response.status === 200 || response.status === 201) {
                setCountry(response.data.payload)
                console.log("setCountry", response);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // Get state list
    const handleChangeCountry = async (event) => {
        const selectedCountryValue = event.target.value;
        setSelectedCountry(selectedCountryValue);
        console.log("SelectedCountry", selectedCountryValue);
        const data = {
            name: selectedCountryValue,
        };
        console.log("data123country", data);
        try {
            const response = await getState(data);
            if (response.status === 200 || response.status === 201) {
                console.log("stateResponse", response.data.states);
                setState(response.data.states);

            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    // Get city list
    const handleChangeState = async (event) => {
        const selectedStateValue = event.target.value;
        setSelectedState(selectedStateValue);
        console.log("SelectedState", selectedStateValue);
        const data = {
            state_name: selectedStateValue,
        }
        console.log("dataState", data);
        try {
            const response = await getCity(data);
            if (response.status === 200 || response.status === 201) {
                console.log("cityResponse", response);
                setCity(response.data.cities)
            }
        } catch (error) {
            console.log("Error sending form data:", error);
        }
    };

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);

    //Get Parent role
    const fetchParentRole = async () => {
        try {
            const response = await getParentRole()
            if (response.status === 200 || response.status === 201) {
                setParentRole(response.data.payload)
                console.log("parentRole", response);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Get Parent user
    const handleChangeUserRole = async (event) => {
        const role_name = event.target.value;
        setSelectedRole(role_name);
        const data = {
            user_role: role_name,
        };
        console.log("data123", data);
        try {
            const response = await getParentUser(data);
            if (response.status === 200 || response.status === 201) {
                console.log("parentUserResponse", response);
                const users = response.data.payload.map(user => ({
                    name: user.name,
                    userId: role_name === "Superadmin" ? user.id : user.user
                }));
                console.log("mapedusers", users);
                setParentUserName(users);
                console.log("parentUserName State Updated:", parentUserName);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Create a New Retailer</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className={`pb-4 pb-md-4 ${sidebarOpen ? 'sidebar-open-distributer' : 'sidebar-close-distributer'}`}>
                {/* ========== Grid 1 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Full Name Of Retailer</label>
                            <TextField
                                id="filled-basic"
                                label="Enter Full Name Of Retailer"
                                variant="filled"
                                className='retailer-input'
                                value={distributerName}
                                onChange={handleChangeDistributerName}
                                InputProps={{
                                    style: {
                                        paddingTop: '5px',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Email ID</label>
                            <TextField
                                id="filled-basic"
                                label="Enter Email ID"
                                variant="filled"
                                className='distributer-input'
                                value={emailAddress}
                                onChange={handleChangeEmail}
                                error={!!emailError}
                                helperText={emailError}
                                FormHelperTextProps={{
                                    style: {
                                        color: 'red', letterSpacing: '1px', fontSize: '16px'
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        paddingTop: '5px',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        {/* <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Mobile Number</label>
                            <TextField
                                id="filled-basic"
                                label="Enter Mobile Number"
                                variant="filled"
                                className='retailer-input'
                                value={mobileNumber}
                                onChange={handleChangeMobile}
                            />
                        </div> */}
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Mobile Number</label>
                            <TextField
                                type="tel"
                                variant="filled"
                                error={mobileNumberError}
                                value={mobileNumber}
                                className='retailer-input'
                                onChange={handleChangeMobile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className="input-adornment-custom" >
                                            +91
                                        </InputAdornment>
                                    ),
                                    style: {
                                        paddingTop: '6px',
                                    },
                                }}
                            />
                            <h3 className={mobileNumberError ? "invalid-message" : ""}>
                                {mobileNumberError ? "Invalid Mobile Number" : ''}
                            </h3>

                        </div>
                    </Grid>
                </Grid>
                {/* ========== Grid 1 End ========== */}
                {/* ========== Grid 2 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Company Name</label>
                            <TextField
                                id="filled-basic"
                                label="Enter Company Name"
                                variant="filled"
                                className='retailer-input'
                                value={companyName}
                                onChange={handleChangeCompanyName}
                                InputProps={{
                                    style: {
                                        paddingTop: '5px',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter GST Number</label>
                            <TextField
                                id="filled-basic"
                                label="Enter GST Number"
                                variant="filled"
                                className='retailer-input'
                                value={gstNumber}
                                onChange={handleChaneGstNumber}
                                error={!!gstError}
                                helperText={gstError}
                                FormHelperTextProps={{
                                    style: {
                                        color: 'red', letterSpacing: '1px', fontSize: '16px'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        paddingTop: '5px',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* ========== Grid 2 End ========== */}
                {/* ========== Grid 3 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Enter Username</label>
                            <TextField
                                id="filled-basic"
                                label="Enter Username"
                                variant="filled"
                                className='retailer-input'
                                value={userName}
                                onChange={handleChangeUserName}
                                InputProps={{
                                    style: {
                                        paddingTop: '5px',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="pt-4">
                        <div className='retailer-input-wrap'>
                            <label className="reatiler-label">Enter Password</label>
                            <FormControl variant="filled" className='retailer-password-input'>
                                <InputLabel htmlFor="filled-adornment-password" className='retailer-password-label'>Enter Your Password</InputLabel>
                                <CustomFilledInput
                                    id="filled-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    InputProps={{
                                        style: {
                                            marginTop: '8px',
                                        },
                                    }}
                                    className="pt-1"
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="pt-4">
                        <div className='retailer-input-wrap'>
                            <label className="reatiler-label">Enter Confirm Password</label>
                            <FormControl variant="filled" className='retailer-password-input'>
                                <InputLabel htmlFor="filled-adornment-password" className='retailer-password-label'>Enter Confirm Password</InputLabel>
                                <CustomFilledInput
                                    id="filled-adornment-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    InputProps={{
                                        style: {
                                            marginTop: '8px',
                                        },
                                    }}
                                     className="pt-1"
                                />
                            </FormControl>
                            {error && <p style={{ color: 'red', letterSpacing: '1px', fontSize: '16px' }}>{error}</p>}
                        </div>
                    </Grid>
                </Grid>
                {/* ========== Grid 3 End ========== */}
                {/* ========== Grid 4 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Parent Role</label>

                            {userDetail.user_role === 'Distributor' ? (
                                <TextField
                                    id="filled-basic"
                                    label="Enter Username"
                                    variant="filled"
                                    className='distributer-input'
                                    value={userDetail.user_role}
                                />
                            ) : (
                                <FormControl variant="filled" className="retailer-country-dropdown">
                                    <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={selectedRole}
                                        onChange={handleChangeUserRole}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {parentRole.map((role) => (
                                            <MenuItem key={role.id} value={role.role_name}>
                                                {role.role_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="pt-4">
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Parent User</label>
                            {userDetail.user_role === 'Distributor' ? (
                                <TextField
                                    id="filled-basic"
                                    label="Enter Username"
                                    variant="filled"
                                    className='distributer-input'
                                    value={userDetail.name}
                                    InputProps={{
                                        style: {
                                            paddingTop: '5px',
                                        },
                                    }}
                                />
                            ) : (
                                <FormControl variant="filled" className="retailer-country-dropdown">
                                    <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={selectedUserId}
                                        onChange={(event) => setSelectedUserId(event.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {/* {parentUserName.map((user, index) => (
         <MenuItem key={index} value={user.userId}>
             {user.name}
         </MenuItem>
     ))} */}
                                        {Array.isArray(parentUserName) && parentUserName.map((user, index) => (
                                            <MenuItem key={index} value={user.userId}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                            }

                        </div>
                    </Grid>
                </Grid >
                {/* ========== Grid 4 End ========== */}
                {/* ========== Grid 5 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Country</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">Search for Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedCountry}
                                    onChange={handleChangeCountry}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {/* {country.map((country) => (
                                        <MenuItem key={country.id} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))} */}
                                    {Array.isArray(country) && country.map((country) => (
                                        <MenuItem key={country.id} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="pt-4">
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">State</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">Search for State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedState}
                                    onChange={handleChangeState}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {/* {state.map((state, index) => (
                                        <MenuItem key={index} value={state}>
                                            {state}
                                        </MenuItem>
                                    ))} */}
                                    {Array.isArray(state) && state.map((state, index) => (
                                        <MenuItem key={index} value={state}>
                                            {state}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="pt-4">
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">City</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">Search for city</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedCity}
                                    onChange={handleChangeCity}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {Array.isArray(city) && city.length > 0 ? (
                                        city.map((city, index) => (
                                            <MenuItem key={index} value={city}>
                                                {city}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>
                                            <em>No cities available</em>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
                {/* ========== Grid 5 End ========== */}
                {/* ========== Grid 6 Start ========== */}
                {/* <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Master Distributor</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">123123123123123</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={masterDistributor}
                                    onChange={handleChangeMasterDistributer}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>123123123123123</MenuItem>
                                    <MenuItem value={20}>123123123123123</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Supper Distributor</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">123123123123123</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={superDistributor}
                                    onChange={handleChangeSuperDistributer}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>123123123123123</MenuItem>
                                    <MenuItem value={20}>123123123123123</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Distributor</label>
                            <FormControl variant="filled" className="retailer-country-dropdown">
                                <InputLabel id="demo-simple-select-filled-label">123123123123123</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={distributor}
                                    onChange={handleDistributer}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>123123123123123</MenuItem>
                                    <MenuItem value={20}>123123123123123</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="retailer-input-wrap">
                            <label className="reatiler-label">Address</label>
                            <TextField
                                label="Address"
                                multiline
                                rows={4}
                                variant="filled"
                                fullWidth
                            />
                        </div>
                    </Grid>
                </Grid> */}
                {/* ========== Grid 6 End ========== */}
                {/* ========== Grid 6 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className="distributer-input-wrap">
                            <label className="distributer-label">Address</label>
                            <TextField
                                label="Address"
                                multiline
                                rows={4}
                                variant="filled"
                                fullWidth
                                value={address}
                                onChange={handleChangeAddress}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* ========== Grid 6 End ========== */}
                <div className="retailer-btn-wrap">
                    <div>
                        <button className="cancel-btn">Cancel</button>
                    </div>
                    <div>
                        <button className="submit-btn" onClick={handleAddRetailer}>Submit</button>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default AddRetailer;