import { request } from "../../api/API"
import { multipartRequest, downloadFile } from "../../api/API"

export const getDistributer = () => {
    return request.get('/custom_panel/getDistributorList/');
}

// export const getRetailer = () => {
//     return request.get('/custom_panel/getRetailerList/')
// }

export const getRetailer = (data) => {
    return request.get(`/custom_panel/getRetailerList/?created_by__name=${data}`)
}

// export const getUserExcel = (data, fromDate) => {
//     return downloadFile(`/custom_panel/getUserListExcel/?search=${data}&from_date=${fromDate}`, "user_data.xlsx",);
// }

export const getUserExcel = (fromDate, deviceStatus, selectedDistributor, selectedRetailer, selectedCountry, selectedState, selectedCity) => {
    return downloadFile(`/custom_panel/getUserListExcel/?&created_at__date=${fromDate}&device_controls__device_status=${deviceStatus}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&distributor_id=${selectedDistributor}&retailer_id=${selectedRetailer}`, "user_data.xlsx",);
}

export const getCountry = () => {
    return request.get('/custom_panel/getCountry/');
}

export const getState = (data) => {
    return request.post('/custom_panel/getState/', data);
}

export const getCity = (data) => {
    return request.post('/custom_panel/getCity/', data);
}

// export const getCustomer = (data, page, pageSize) => {
//     return request.get('/customer/customerList/', { params: data, page: page + 1, page_size: pageSize, })
// }

export const getCustomer = (data, page, pageSize) => {
    return request.get('/customer/customerList/', { params: { ...data, page: page + 1, page_size: pageSize, }, });
}

export const getRetailerView = (id) => {
    return request.get(`/custom_panel/getRetailerList/${id}`);
}


// User view 

export const getCustomerView = (id) => {
    return request.get(`/customer/customerList/${id}`);
}