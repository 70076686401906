import React, { useState, useRef, useEffect } from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { getDistributer, getRetailer, getCountry, getState, getCity, getCustomer, getUserExcel } from "./service";
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast, ToastContainer } from 'react-toastify';
import { downloadFile } from "../../api/API"
import "react-toastify/dist/ReactToastify.css";
import './UserList.css';


const columns = [
    { id: 'sr', label: 'Sr' },
    { id: 'codeno', label: 'Code No' },
    { id: 'mobile', label: 'Mobile' },
    { id: 'fullName', label: 'Name' },
    { id: 'superdisributer', label: 'Super Disributer' },
    { id: 'distributer', label: 'Disributer' },
    { id: 'retailer', label: 'Retailer' },
    { id: 'codeDate', label: 'Code Date' },
    { id: 'installType', label: 'Install Type' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
];

const UserList = () => {

    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [city, setCity] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [status, setStatus] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sr');
    const [action, setAction] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [distributerData, setDistributerData] = useState([]);
    const [selectedDistributor, setSelectedDistributor] = useState('');
    const [retailerData, setRetailerData] = useState([]);
    const [selectedRetailer, setSelectedRetailer] = useState('');
    const [selectedFormDate, setSelectedFormDate] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [dropdownValues, setDropdownValues] = useState({});
    const [sortedDataList, setSortedDataList] = useState([]);
    const [refresh, setRefresh] = useState(-1);
    const [count, setCount] = useState(0);
    const isInitialRender = useRef(true);

    const navigate = useNavigate();

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleClick = () => {
        navigate('/addDistributer')
    }

    const handleClickUserView = () => {
        navigate('/userView');
    }

    const handleChangeCompany = (event) => {
        setcompanyName(event.target.value);
    }

    const handleSort = (property, order) => {
        setOrder(order);
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchDistributerData();
        fetchRetailerData();
    }, []);


    const handleChangeDistributer = (event) => {
        const distributor = event.target.value;
        setSelectedDistributor(distributor);
        if (distributor) {
            const selectedId = distributor.id;
            const selectedName = distributor.name;
            console.log("Selected Distributor ID:", selectedId);
            console.log("Selected Distributor Name:", selectedName);
            fetchRetailerData(selectedName);
        } else {
            console.log("No distributor selected");
        }
    };

    const handleChangeRetailer = (event) => {
        const retailer = event.target.value;
        setSelectedRetailer(retailer);
        if (retailer) {
            const selectedRetailerId = retailer.id;
            const selectedName = retailer.name;
            const selectCreatedByName = retailer.created_by;
            console.log("Selected retailer ID:", selectedRetailerId);
            console.log("Selected retailer Name:", selectedName);
            console.log("Selected retailer createdBy:", selectCreatedByName);
            console.log("retailer", retailer)
            // fetchRetailerData(selectCreatedByName);
        } else {
            console.log("No distributor selected");
        }
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleFormdateChange = (newDate) => {
        setSelectedFormDate(newDate);
        const formDate = dayjs(newDate).format('YYYY-MM-DD');
        console.log("Formatted Date:", formDate);
    };

    //Get Distributer list
    const fetchDistributerData = async () => {
        try {
            const response = await getDistributer()
            if (response.status === 200 || response.status === 201) {
                setDistributerData(response.data.results)
                console.log("setDistributerData", response.data.results);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Get Retailer list
    const fetchRetailerData = async (name) => {
        try {
            const response = await getRetailer(name)
            if (response.status === 200 || response.status === 201) {
                setRetailerData(response.data.results)
                console.log("setRetailerData", response.data.results);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // useEffect(() => {
    //     fetchCountry();
    // }, []);


    //Get Country list
    // const fetchCountry = async () => {
    //     try {
    //         const response = await getCountry()
    //         if (response.status === 200 || response.status === 201) {
    //             setCountry(response.data.payload)
    //             console.log("setCountry", response);
    //         }

    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // }

    // Get state list
    // const handleChangeCountry = async (event) => {
    //     const selectedCountryValue = event.target.value;
    //     setSelectedCountry(selectedCountryValue);
    //     console.log("SelectedCountry", selectedCountryValue);
    //     const data = {
    //         name: selectedCountryValue,
    //     };
    //     console.log("data123country", data);
    //     try {
    //         const response = await getState(data);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log("stateResponse", response.data.states);
    //             setState(response.data.states);

    //         }
    //     } catch (error) {
    //         console.error("Error sending form data:", error);
    //     }
    // };

    // Get city list
    // const handleChangeState = async (event) => {
    //     const selectedStateValue = event.target.value;
    //     setSelectedState(selectedStateValue);
    //     console.log("SelectedState", selectedStateValue);
    //     const data = {
    //         state_name: selectedStateValue,
    //     }
    //     console.log("dataState", data);
    //     try {
    //         const response = await getCity(data);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log("cityResponse", response);
    //             setCity(response.data.cities)
    //         }
    //     } catch (error) {
    //         console.log("Error sending form data:", error);
    //     }
    // };

    // const handleChangeCity = (event) => {
    //     const selectedCityValue = event.target.value;
    //     setSelectedCity(selectedCityValue);
    // }

    const handleChangeAction = async (event, rowId) => {
        const selectedAction = event.target.value;
        setDropdownValues(prevValues => ({
            ...prevValues,
            [rowId]: selectedAction,
        }));

        if (selectedAction === "view") {
            navigate(`/userView/${rowId}`);
        }
        if (selectedAction === "device") {
            // navigate('/financeLocker');
            navigate(`/financeLocker/${rowId}`);
        }
    };


    const handleSubmit = async (isShowNotification) => {
        console.log("HANDLE SUBMIT CALLED !! ", isShowNotification)
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const deviceStatus = status === 'Blocked' ? true : status === 'Unblocked' ? false : null;
        const data = {
            distributor_id: selectedDistributor ? selectedDistributor.id : null,
            retailer_id: selectedRetailer ? selectedRetailer.id : null,
            device_controls__device_status: deviceStatus,
            created_at__date: fromDate,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
        };
        console.log("customer", data);
        console.log("Page:", page);
        console.log("Page Size:", rowsPerPage);
        console.log("Data:", data);

        try {
            const pageSize = rowsPerPage || 10;
            const response = await getCustomer(data, page, pageSize);
            if (response.status === 200 || response.status === 201) {
                setSortedDataList([]);
                const results = response.data.payload.results;
                console.log("setCustomerData", response.data.payload.results);
                setSelectedFormDate(null);

                if (results.length === 0) {
                    errorNotify("Customer not found");
                    setCount(0);
                    setCustomerData([]);
                } else {

                    console.log("count", response.data)
                    setCustomerData(response.data.payload.results);
                    setCount(response.data.payload.count);
                    // console.log("reaponseretailer", response.data.results)
                    console.log("ISNOTIFICATION SHOW :- ", isShowNotification);
                    if (isShowNotification !== false) {
                        notify(response.data.message);
                    }

                }
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    const handleRefresh = () => {
        setSelectedCountry("");
        setSelectedState("");
        setSelectedCity("");
        setSelectedDistributor(null);
        setStatus("");
        setRetailerData([]);

        setRefresh(refresh === 0 || refresh === -1 ? 1 : 0);
    };

    const handleExportExcel = async () => {
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const deviceStatus = status === 'Blocked' ? true : status === 'Unblocked' ? false : null;
        // const data = {
        //     distributor_id: selectedDistributor ? selectedDistributor.id : null,
        //     retailer_id: selectedRetailer ? selectedRetailer.id : null,
        //     device_controls__device_status: deviceStatus,
        //     created_at__date: fromDate,
        //     country: selectedCountry,
        //     state: selectedState,
        //     city: selectedCity,
        // };
        const data = {
            distributor_id: selectedDistributor ? selectedDistributor.id : null,
            retailer_id: selectedRetailer ? selectedRetailer.id : null,
            device_controls__device_status: deviceStatus !== null ? deviceStatus : "",
            created_at__date: fromDate,
            country: selectedCountry || "",
            state: selectedState || "",
            city: selectedCity || "",
        };
        console.log("userListExcel", data);
        try {
            // const response = await getUserExcel(data, fromDate, deviceStatus, selectedDistributor, selectedRetailer, selectedCountry, selectedState, selectedCity);
            const response = await getUserExcel(data);
            console.log("response", response);
        }
        catch (error) {
            console.error("Error sending form data:", error);
        }
    }

    const getUserExcel = (data) => {
        // Convert data object to query parameters
        const queryParams = new URLSearchParams();

        for (const key in data) {
            if (data[key] !== null && data[key] !== "") {
                queryParams.append(key, data[key]);
            }
        }

        const queryString = queryParams.toString();
        const url = `/custom_panel/getUserListExcel/?${queryString}`;

        return downloadFile(url, "user_data.xlsx");
    };


    useEffect(() => {
        console.log("REFRESH CALLED !!");
        var isShowNotification = true;
        if (refresh === -1) {
            isShowNotification = false;
        }
        handleSubmit(isShowNotification);
    }, [refresh]);

    const sortData = () => {
        setSortedDataList([...customerData].sort((a, b) => {
            let valueA = a[orderBy];
            let valueB = b[orderBy];
            // Handle undefined values
            // Handle undefined or null values
            if (valueA === undefined || valueA === null) valueA = '';
            if (valueB === undefined || valueB === null) valueB = '';


            // Check if both values are numeric
            if (!isNaN(valueA) && !isNaN(valueB)) {
                valueA = parseFloat(valueA);
                valueB = parseFloat(valueB);
            } else {
                // Ensure strings are compared case-insensitively
                valueA = valueA.toString().toLowerCase();
                valueB = valueB.toString().toLowerCase();
            }

            if (order === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        }));
    };

    useEffect(() => {
        sortData();
    }, [order, orderBy, customerData]);

    useEffect(() => {
        // Skip the effect logic on the initial render
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        handleSubmit(false);
    }, [page, rowsPerPage]);


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4">
                <div className="header-text-wrap">
                    <div className="total-count">
                        <h2 className="header-text">User List</h2>
                        <p>Total count - {count}</p>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-4">
                <div className="search-data-bg">
                    <div className="user-list-form">
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="user-list-box">
                                    <label className="user-list-label">Distributor</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={selectedDistributor}
                                            onChange={handleChangeDistributer}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {distributerData.map((distributor) => (
                                                <MenuItem key={distributor.id} value={distributor}>
                                                    {distributor.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="user-list-box">
                                    <label className="user-list-label">Retailer</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={selectedRetailer}
                                            onChange={handleChangeRetailer}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {retailerData.map((reatiler) => (
                                                <MenuItem key={reatiler.id} value={reatiler}>
                                                    {reatiler.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="user-list-box">
                                    <label className="user-list-label">Status</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={status}
                                            onChange={handleChangeStatus}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Unblocked">Unblock</MenuItem>
                                            <MenuItem value="Blocked">Block</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoItem label="From Date">
                                        <DesktopDatePicker
                                            value={selectedFormDate}
                                            onChange={handleFormdateChange}
                                            className="custom-input"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </DemoItem>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="user-list-box">
                                    <label className="user-list-label">Country</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Search for country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {country.map((country) => (
                                                <MenuItem key={country.id} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="user-list-box">
                                    <label className="user-list-label">State</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Search for state</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={selectedState}
                                            onChange={handleChangeState}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {state.map((state, index) => (
                                                <MenuItem key={index} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4">
                                <div className="user-list-box">
                                    <label className="user-list-label">City</label>
                                    <FormControl variant="filled" className="user-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="user-details-dropdown-label">Search for city</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="user-list-dropdown"
                                            value={selectedCity}
                                            onChange={handleChangeCity}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {Array.isArray(city) && city.length > 0 ? (
                                                city.map((city, index) => (
                                                    <MenuItem key={index} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>
                                                    <em>No cities available</em>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4">
                            <div className="userlist-btns-wrap">
                                <div>
                                    <MdRefresh className="refresh-icon" onClick={handleRefresh} />
                                </div>
                                <div>
                                    <button className="cancel-btn" onClick={handleExportExcel}>Export</button>
                                </div>
                                <div>
                                    <button className="submit-btn" onClick={() => handleSubmit(true)}>Submit</button>
                                </div>
                            </div>
                        </Grid>
                    </div>
                </div>
            </Container>

            <Container maxWidth="xxl" className="pt-4 pb-5">
                <TableContainer component={Paper}>
                    <Table className="transaction-list-table-wrap">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <>
                                        <TableCell key={column.id} className="transaction-list-table-header-bg"
                                            sx={{
                                                border: 'none',
                                                '&:last-child': { border: 0 }
                                            }}
                                        >
                                            <div className="header-text-wrapper">
                                                <div className="header-text">
                                                    {column.label}
                                                </div>

                                                <div>
                                                    <div>
                                                        <IoIosArrowUp
                                                            onClick={() => handleSort(column.id, 'desc')} className="up-icon"
                                                        >
                                                        </IoIosArrowUp>
                                                    </div>

                                                    <div>
                                                        <IoIosArrowDown
                                                            onClick={() => handleSort(column.id, 'asc')} className="down-icon"
                                                        >
                                                        </IoIosArrowDown>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="transaction-list-table">
                            {sortedDataList.map((row, index) => (


                                <TableRow key={row.retailer.id}>
                                    <TableCell sx={{ padding: 0 }}>{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell sx={{ padding: 0 }}>{row.code_no}</TableCell>
                                    <TableCell onClick={handleClickUserView}>{row.retailer.mobile_number}</TableCell>
                                    <TableCell>{row.full_name}</TableCell>
                                    <TableCell>{row.distributor.created_by || row.retailer.created_by}</TableCell>
                                    <TableCell>{row.retailer.distributor}</TableCell>
                                    <TableCell>{row.retailer.name}</TableCell>
                                    <TableCell>{new Date(row.retailer.created_at).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        {row.install_type}
                                    </TableCell>
                                    <TableCell>
                                        <button className={`status ${row.device_controls.device_status ? 'status-block' : 'status-unblock'}`}>
                                            {row.device_controls.device_status ? 'Block' : 'Unblock'}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ m: 1 }} className="distributer-list-action-dropdown">
                                            <Select
                                                value={dropdownValues[row.id] || "action"}
                                                onChange={(e) => handleChangeAction(e, row.id)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className="distributer-list-action-dropdown"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    fontFamily: 'Satoshi-Regular',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.5px'
                                                }}
                                            >
                                                <MenuItem value="action">
                                                    Action
                                                </MenuItem>
                                                <MenuItem value="view">View</MenuItem>
                                                <MenuItem value="device">Manage Device</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </TableContainer>

            </Container>
        </>
    )
}

export default UserList;

