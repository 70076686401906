import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import { getCustomerView } from "./service";

const UserView = () => {

    const [userData, setUserData] = useState([]);

    const params = useParams();
    console.log(params, "id")

    useEffect(() => {
        fetchData();
    }, [params.id]);


    console.log("getUserView", userData)
    const fetchData = async () => {
        try {
            const response = await getCustomerView(params.id)
            if (response.status === 200 || response.status === 201) {
                setUserData(response.data);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <>
            <Container maxWidth="xxl" className="pt-4 pb-4">
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">User View</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-2 pb-4">
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <div className="user-view-box">
                            <div>
                                <h6>Customer  Details</h6>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Name :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.name ? userData.name : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Company name :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.company_name ? userData.company_name : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Mobile :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.mobile_number ? userData.mobile_number : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Email id :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.email ? userData.email : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">parent name :</span>
                                </div>
                                <div>
                                    <span className="detail-value">{userData.created_by ? userData.created_by : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Owner Name :</span>
                                </div>
                                <div>
                                    <span className="detail-value">{userData.name ? userData.name : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">country :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.country ? userData.country : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">State :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.state ? userData.state : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">City :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.city ? userData.city : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Address :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData && userData.address ? userData.address : '-'}</span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>

        </>
    );
}

export default UserView;
