import axios from "axios";
import store from "../redux/store/store";
import Cookies from "js-cookie"; 
import { url } from "./url";

export const request = axios.create({
  baseURL: url.apiBaseUrl + "/api/v1",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
});

// request.interceptors.request.use(
//   function (config) {
//     const user = store.getState().userLogin;
//     // console.log("store", store);
//     // console.log("user123", user);

//     if (user?.userDetail?.token) {
//       config.headers.Authorization = `token ${user?.userDetail?.token}`;
//     }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

request.interceptors.request.use(
  function (config) {
    const user = store.getState().userLogin;

    // Attach CSRF token to headers if it exists
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }

    // Attach Authorization token if user is logged in
    if (user?.userDetail?.token) {
      config.headers.Authorization = `token ${user?.userDetail?.token}`;
    }
    
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized errors
      console.error('Unauthorized access. Redirecting to login...');
      localStorage.clear(); // Clear local storage
      window.location.href = '/login'; // Redirect to login
    }
    return Promise.reject(error);
  }
);

export const multipartRequest = axios.create({
  baseURL: url.apiBaseUrl + "/api/v1",
  headers: {
    "Accept": "application/json",
    "Content-Type": "multipart/form-data",
  },
});

// multipartRequest.interceptors.request.use(
//   function (config) {
//     const user = store.getState().userLogin;
//     console.log('User Token:', user?.userDetail.token);
//     if (user?.userDetail.token) {
//       config.headers.Authorization = `token ${user?.userDetail.token}`;
//     }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

multipartRequest.interceptors.request.use(
  function (config) {
    const user = store.getState().userLogin;
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    if (user?.userDetail?.token) {
      config.headers.Authorization = `token ${user?.userDetail?.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const token = localStorage.getItem('authToken');
console.log('Token from local storage:', token);


multipartRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized errors
      console.error('Unauthorized access. Redirecting to login...');
      localStorage.clear(); // Clear local storage
      window.location.href = '/login'; // Redirect to login
    }
    return Promise.reject(error);
  }
);



// Function to download a file as Blob
export const downloadFile = async (endpoint, filename) => {
  try {
    const response = await multipartRequest.get(endpoint, {
      responseType: 'blob', // Important to set the response type to 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    // Set the downloaded file name
    link.setAttribute('download', filename);

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('There was an error downloading the file:', error);
  }
};

