import { request } from "../../api/API"
import { multipartRequest } from "../../api/API"

export const addRetailer = (data) => {
    return multipartRequest.post('/custom_panel/addRetailer/', data);
}

export const getParentRole = () => {
    // return request.get('/custom_panel/showUserRole/');
    return request.get('/custom_panel/showRetailerUserRole/');
}

export const getParentUser = (data) => {
    return multipartRequest.post('/custom_panel/userRoleList/', data);
}

export const getCountry = () => {
    return request.get('/custom_panel/getCountry/');
}

export const getState = (data) => {
    return request.post('/custom_panel/getState/', data);
}

export const getCity = (data) => {
    return request.post('/custom_panel/getCity/', data);
}