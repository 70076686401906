import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { getTransactionView, updateTransferPoint } from '../MyTransaction/service';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const TransactionView = () => {

    const [userData, setUserData] = useState([]);
    const [editUserData, setEditUserData] = useState({
        revert_points: "",
        points: ""
    })


    const params = useParams();
    console.log(params, "id")

    useEffect(() => {
        fetchData();
    }, [params.id]);

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const fetchData = async () => {
        try {
            const response = await getTransactionView(params.id)
            if (response.status === 200 || response.status === 201) {
                setUserData(response.data);
                setEditUserData((prevState) => ({
                    ...prevState,
                    points: response.data.points || ""
                }));
                console.log("userDatauserDataTransfer", response.data)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleOnChange = (e) => {
        setEditUserData({ ...editUserData, [e.target.name]: e.target.value })
    }

    const handleUpdate = async () => {
        const updatedData = {
            transaction_id: params.id,
            revert_points: editUserData.points
        };
        console.log("updatedData", updatedData)

        try {
            const response = await updateTransferPoint(params.id, updatedData);
            if (response.status === 200 || response.status === 201) {
                console.log("Update successful:", response.data);
                fetchData();
                notify(response.data.message);

            } else {
                console.error("Error updating data:", response);
            }
        } catch (error) {
            console.error("Error updating data:", error);
            if (error.response && error.response.data && error.response.data.error) {
                errorNotify(error.response.data.error); 
            } else {
                errorNotify("An error occurred while fetching data");
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4 pb-4">
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Transaction View</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-2 pb-4">
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <div className="user-view-box">
                            {/* <div>
                                <h6>Customer  Details</h6>
                            </div> */}
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Transaction Number :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.transaction_no ? userData.transaction_no : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Transaction From :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.transaction_from ? userData.transaction_from : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Transaction To :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.transaction_to ? userData.transaction_to : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Transaction Date :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.transaction_date ? userData.transaction_date : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Point :</span>
                                </div>
                                <div>
                                    <input
                                        value={editUserData.points}
                                        name="points"
                                        onChange={(e) => handleOnChange(e)}
                                        className='detail-input'
                                    />
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Type :</span>
                                </div>
                                <div>
                                    <span className="detail-value">{userData.transaction_type ? userData.transaction_type : '-'}</span>
                                </div>
                            </div>
                            <div className="user-detail-wrap">
                                <div className="user-detail">
                                    <span className="detail-label">Balance Type :</span>
                                </div>
                                <div className="user-detail-input-wrap">
                                    <span className="detail-value">{userData.balance_type ? userData.balance_type : '-'}</span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className="disatributer-update-button-wrap">
                <button onClick={handleUpdate} className='disatributer-update-button mb-4'>
                    Update
                </button>
            </div>

        </>
    );
}

export default TransactionView;
