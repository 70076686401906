import { request } from "../../api/API"
import { multipartRequest } from "../../api/API"

export const getTransactionSummary = () => {
    return request.get('/custom_panel/balanceSummary/');
}

export const getShowUserRole = () => {
    // return request.get('/custom_panel/showUserRole/');
    return request.get('/custom_panel/showTransactionUserRole/')
}

export const getTransferName = (data) => {
    return multipartRequest.post('/custom_panel/userRoleList/', data);
}

export const addTransfer = (data) => {
    return multipartRequest.post('/custom_panel/transferPoints/', data);
}

export const getDistributerView = (id) => {
    return request.get(`/custom_panel/getDistributorList/${id}`);
}
